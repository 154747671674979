
import Vue from "vue";
import Selector from "@/components/selectores/selector.vue";
import { mdiBankOutline } from "@mdi/js";
import selectoresModule from "@/store/modules/selectoresModule";
import { Banorte } from "apd.models";
import spinnerModule from "@/store/modules/spinnerModule";

export default Vue.extend({
  name: "SelectAfiliaciones",
  components: {
    Selector,
  },
  props: {
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    conciliables: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        afiliacion: mdiBankOutline,
      } as Record<string, string>,
    };
  },
  computed: {
    afiliaciones(): Banorte.Payworks2.Afiliacion[] {
      if (this.$props.conciliables) {
        return selectoresModule.afiliacionesConciliablesBanorte;
      } else {
        return selectoresModule.afiliacionesBanorte;
      }
    },
    loading(): boolean {
      return selectoresModule.loading;
    },
  },
  watch: {
    loading(val: boolean) {
      if (val) {
        spinnerModule.Show();
      } else {
        spinnerModule.Hide();
      }
    },
  },
  async created() {
    if (this.$props.conciliables) {
      await selectoresModule.LoadAfiliacionesConciliablesBanorte();
    } else {
      await selectoresModule.LoadAfiliacionesBanorte();
    }
  },
  methods: {
    change(data: string | string[]) {
      this.$emit("change", data);
    },
  },
});
